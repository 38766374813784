
export default {
    name: 'Currency',
    data() {
        return {
            currency: {},
            list: []
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.currency = this.$storage.get('currency') || {}
        this.getLanguageAjax()
    },
    methods: {
        getLanguageAjax() {
            this.$api.common.getCurrency().then(response => {
                const res = response.result || [];
                this.list = res
            })
        },
        handleRadio(obj) {
            this.$storage.set('currency', obj, true)
            this.$router.back()
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Currency'),
                keywords: this.$translate('Currency'),
                description: this.$translate('Currency'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
    },
}
