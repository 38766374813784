
export default {
    name: 'edmSubscribe',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasUnSubscribe: false
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 接口
        unSubscribeAjax() { // 取消订阅接口
            this.$api.help.setUnSubscribe().then(response => {
                const res = response.result || {};
                this.hasUnSubscribe = true
            })
        },
        // 取消订阅
        handleUnSubscribe() {// 是否登录
            if (!this.$login()) {
                this.$router.push({
                    name: 'Login'
                })
                return;
            }
            this.unSubscribeAjax()
        },
        // 跳转官网首页
        jumpLink() {
            this.$router.push({
                name: 'Home'
            })
        }
    }
}
