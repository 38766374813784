
import fnApi from '/utils/common/methods'

export default {
    name: 'custonIndex',
    components: {},
    async asyncData({ $api, cookies, ...context }) { },
    data() {
        return {
            fnApi: fnApi,      // 通用方法
            skeleton: true,
            customizeCompo: {
                visible: false,
                url: '',
                hasPopup: false,
                on: {},
                height: '100%'
            },
            middleEmit: {}
        }
    },
    head() {
        return this.setHead()
    },
    created() {
    },
    mounted() {
        this.initialSet()
    },
    methods: {
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Design Your Own Style | YFN Jewelry'),
                keywords: this.$translate('Design Your Own Style | YFN Jewelry'),
                description: this.$translate('Supprot photo, engraving Design. Unique and interesting, 1000+ people are trying now!'),
                image: 'https://m.yfn.com/assets/new/icon/custom.png'
            });
            return {
                ...head
            }
        },
        // 定制交互
        setCustomizeFn() {
            this.customizeCompo.on = {
                updateBagQuantity: obj => { // 定制完成购物车更新
                    this.middleEmit.fn('getBagAjax')
                }
            }
        },
        setUrl(){
            let query = this.$query
            this.customizeCompo.url = `${location.origin}/custonIndex/customList`
            query.scenario && (this.customizeCompo.url += `?applicationScenario=${query.scenario}`)
        },
        // 初始化
        initialSet() {
            this.middleEmit.fn = (fn, options, callback) => {
                this[fn] && this[fn](options)
            }
            this.setCustomizeFn();
            this.setUrl();
            this.customizeCompo.visible = true
            this.skeleton = false
        }
    },
}
